import { useState } from 'react'
import { Dialog, Popover } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import PathConstants from '../routes/pathConstants'

const Header = () => {  

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <header className='bg-white fixed z-10 w-full shadow'>
            <nav className='mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8' aria-label='Global'>    
                <div className='flex lg:flex-1'>
                    <a href={PathConstants.HOME} className='-m-1.5 p-1.5'>
                        <span className='relative text-color1'>Hardev App</span>
                    </a>
                </div>
                <div className='flex lg:hidden'>
                    <button type='button' className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700' aria-label='Open menu'
                        onClick={() => setMobileMenuOpen(true)} >
                            <Bars3Icon className='h-6 w-6' aria-hidden='true' />
                    </button>
                </div>
                <Popover.Group className='hidden lg:flex lg:gap-x-12'>
                    <a href={PathConstants.NOTIFYPUG} className='text-sm font-semibold leading-6 text-gray-900'>
                        NotifyPug
                    </a>
                </Popover.Group>
            </nav>
            <Dialog as='div' className='lg:hidden' open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className='fixed inset-0 z-10'/>
                <Dialog.Panel className='fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
                    <div className='flex items-center justify-between'>
                        <a href={PathConstants.HOME} className='-m-1.5 p-1.5'>
                            <span className='sr-only text-color3'>Hardev App</span>
                        </a>
                        <button type='button' className='-m-2.5 rounded-md p-2.5 text-gray-700' aria-label='Close menu'
                            onClick={() => setMobileMenuOpen(false)} >
                                <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                        </button>
                    </div>
                    <div className='mt-6 flow-root'>
                        <div className='-my-6 divide-y divide-gray-500/10'>
                            <div className='space-y-2 py-6'>      
                                <a href={PathConstants.NOTIFYPUG} className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                                    NotifyPug
                                </a>
                            </div>
                            <div className='py-6'>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
      </header>
    )
};
  
export default Header;